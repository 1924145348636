export default {
    accountingmonth: "Accounting month",
    accrual: "Accrual",
    accruals: "Accruals",
    AQSI_account: "AQSI account",
    balance_end: "Balance end",
    balance_start: "Balance start",
    bonus_movement: "Bonus movement",
    cash_movements: "Cash movements",
    cash_received: "Cash received",
    closed: "Closed",
    communal: "Utility bills",
    deduct_materials_from_doctor_salary: "Deduct materials from doctors' salary",
    deduction: "Deduction",
    deposit_account: "Deposit account",
    deposit_accounts: "Deposit accounts",
    deposit_balance: "Deposit balance",
    deposit_balance_end: "Deposit balance end",
    deposit_balance_start: "Deposit balance start",
    deposit_movement: "Deposit movement",
    employee: "Employee",
    employee_id: "Employee Id",
    expense: "Expense",
    first_payment: "First payment",
    income: "Income",
    left_to_pay: "Left to pay",
    manual_adjustments: "Manual adjustments",
    materials_expense: "Expendable material",
    open: "Open",
    other_expense: "Other expenses",
    other_income: "Other income",
    pay_out: "Pay out",
    payment_comment: "Payment comment",
    payment_sum: "Amount to be paid",
    profit: "Income",
    recalculate: "Recalculate",
    refund_to_patient: "Refund to patient",
    rent: "Rent",
    salaries: "Wages",
    unclosed: "Unclosed",
    upselling: "Upselling"
}
