export default {
    accountingmonth: "Büdcə ayı",
    accrual: "Hesablama",
    accruals: "Hesablamalar",
    AQSI_account: "AQSI hesab",
    balance_end: "Dövriyyənin sonu üzrə balans",
    balance_start: "Dövriyyənin əvvəlki üzrə balans",
    bonus_movement: "Bonusların hərəkəti",
    cash_movements: "Pul vəsaitlərinin hərəkəti",
    cash_received: "Pul vəsaitləri alındı",
    closed: "Bağlı",
    communal: "Kommunal ödənişlər",
    deduct_materials_from_doctor_salary: "Həkimlərin maaşından materialların tutulması",
    deduction: "Tutma",
    deposit_account: "Depozit hesabı",
    deposit_accounts: "Depozit hesabları",
    deposit_balance: "Depozit qalığı",
    deposit_balance_end: "Dövriyyənin sonu üzrə depozit qalığı",
    deposit_balance_start: "Dövriyyənin əvvəlki üzrə depozit qalığı",
    deposit_movement: "Depozit üzrə hərəkətlər",
    employee: "İşçi",
    employee_id: "İşçi ID",
    expense: "Xərc",
    first_payment: "İlk ödəniş",
    income: "Gəlirlər",
    left_to_pay: "Qalıq məbləğ",
    manual_adjustments: "Əl ilə tənzimləmələr",
    materials_expense: "Materiallar üzrə xərc",
    open: "Açıq",
    other_expense: "Digər xərclər",
    other_income: "Digər gəlirlər",
    pay_out: "Ödəmək",
    payment_comment: "Ödəniş üçün şərh",
    payment_sum: "Ödəniləcək məbləğ",
    profit: "Gəlir",
    recalculate: "Yenidən hesabla",
    refund_to_patient: "Hasta geri ödəniş",
    rent: "İcarə",
    salaries: "Maaşlar",
    unclosed: "Bağlanmamış",
    upselling: "Yuxarı satış"
}
